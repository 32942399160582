<template>
     <div class="designer_application_orderList">
          <div class="title">采购申请单</div>
          <div class="des_app_list">
               <div class="list_state flex_row_between_center">
                    <div class="flex_row_start_center">
                         <div :class="{state:true,chosen:curState==state.val}" v-for="(state,index) in stateList"
                              :key="index" @click="selState(state.val)">{{state.desc}}</div>
                    </div>
                    <div class="state_search flex_row_between_center">
                         <input type="text" placeholder="申请单号/设计师名称/用户名/手机号" v-model="keyword">
                         <span class="el-icon-close" @click="search('clear')" v-if="keyword"></span>
                         <div class="icon flex_row_center_center">
                              <span class="iconfont iconsousuo-" @click="search('do')"></span>
                         </div>
                    </div>
               </div>
               <div class="list_table">
                    <el-table ref="multipleTable" :data="appList" style="width: 100%"
                         @selection-change="handleSelectionChange">
                         <el-table-column property="applySn" label="申请单号" width="140" />
                         <el-table-column property="designerName" label="设计师名称" width="120" :formatter="formatter" />
                         <el-table-column property="memberName" label="用户名" width="120" :formatter="formatter" />
                         <el-table-column property="memberMobile" label="手机号" width="120" :formatter="formatter" />
                         <el-table-column property="createTime" label="申请时间" width="190" />
                         <el-table-column property="stateValue" label="状态" width="120" />
                         <el-table-column property="refuseReason" label="拒绝理由" width="120" :formatter="formatter"
                              :show-overflow-tooltip="true" />
                         <el-table-column label="操作" fixed="right" width="160" align="center">
                              <template #default="scope">
                                   <div class="options flex_row_start_center">
                                        <span class="txt17" @click="toDetail(scope.row)">查看详情</span>
                                        <span class="txt17" @click="viewOrder(scope.row.applySn)"
                                             v-if="scope.row.state!=4&&scope.row.state!=1">查看订单</span>
                                   </div>
                              </template>
                         </el-table-column>
                    </el-table>
               </div>

               <!-- 分页 -->
               <div class="flex_row_center_center sld_pagination">
                    <el-pagination @current-change="handleCurrentChange" v-model:currentPage="page.current"
                         :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
                         :hide-on-single-page="true">
                    </el-pagination>
               </div>
          </div>
          <el-dialog v-model="visiOrder" width="790px" lock-sroll="false">
               <div class="group1 flex-col">
                    <div class="main1 flex-col">
                         <div class="mod1 flex-row">
                              <span class="txt1">查看订单</span>
                              <div class="label1 el-icon-close" @click="visiOrder=false"></div>
                         </div>
                    </div>
                    <div class="main22 flex-col">
                         <div class="mod2 flex-col">
                              <div class="group2 flex-row">
                                   <div class="word0"></div>
                                   <div class="word1">订单号</div>
                                   <div class="word2">采购单号</div>
                                   <div class="word3">供应商</div>
                                   <div class="word4">订单状态</div>
                                   <div class="word5">操作</div>
                              </div>
                              <div v-if="orderList.length">
                                   <el-scrollbar>
                                        <div class="group3 flex-row" v-for="(item,index) in orderList" :key="index">
                                             <span class="word6">{{index+1}}</span>
                                             <span class="txt2">{{item.orderSn}}</span>
                                             <span class="info1">{{item.purchaseSn?item.purchaseSn:'--'}}</span>
                                             <span class="word7">{{item.storeName}}</span>
                                             <span class="txt3">{{item.orderStateValue}}</span>
                                             <router-link :to="`/member/order/info?orderSn=${item.orderSn}`"
                                                  target="_blank">
                                                  <button class="bd2 flex-col">
                                                       <span class="word8">查看详情</span>
                                                  </button>
                                             </router-link>
                                        </div>
                                   </el-scrollbar>
                              </div>
                              <div v-else>
                                   <SldCommonEmpty :totalHeight="260" :totalWidth="747" :paddingTop="60">
                                   </SldCommonEmpty>
                              </div>
                         </div>
                    </div>
               </div>
          </el-dialog>
     </div>
</template>

<script>
     import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
     import { useStore } from "vuex";
     import { ElMessage } from "element-plus";
     import { useRoute, useRouter } from "vue-router";
     import SldCommonEmpty from '@/components/SldCommonEmpty'
     export default {
          components: {
               SldCommonEmpty
          },
          setup() {
               const router = useRouter()
               const route = useRoute()
               const { proxy } = getCurrentInstance();
               const visiOrder = ref(false)
               const orderList = ref([])
               const state = reactive({
                    page: {},
                    stateList: [
                         { desc: '全部', val: 0 },
                         { desc: '待审核', val: 1 },
                         { desc: '采购中', val: 2 },
                         { desc: '已完成', val: 3 },
                         { desc: '已拒绝', val: 4 },
                    ],
                    curState: 0,
                    keyword: ''
               })
               const appList = ref([])
               const param = reactive({
                    current: 1,
                    pageSize: 10
               })

               const selState = (val) => {
                    state.curState = val
                    let query = {}
                    if (state.curState) query.state = val
                    router.push({
                         path: '/member/desApp/list',
                         query
                    })
               }

               const getAppList = () => {
                    proxy.$get('v3/member/front/member/purchase/apply/list', param).then(res => {
                         if (res.state == 200) {
                              appList.value = res.data.list
                              state.page = res.data.pagination
                         }
                    })
               }

               const search = (type) => {
                    if (type == 'do') {
                         param.keyword = state.keyword
                         getAppList()
                    } else {
                         state.keyword = ''
                         delete param.keyword
                         getAppList()
                    }

               }

               const toDetail = (scopeItem) => {
                    router.push({
                         path: '/member/desApp/detail',
                         query: {
                              appId: scopeItem.applyId
                         }
                    })
               }

               const handleCurrentChange = (e) => {
                    param.current = Math.floor(e);
                    getAppList()
               }

               const viewOrder = (applySn) => {
                    visiOrder.value = true;
                    proxy.$get('v3/business/front/orderInfo/orderList', { applySn }).then(res => {
                         if (res.state == 200) {
                              orderList.value = res.data
                         }
                    })
               }

               const formatter = (row, column, cellValue, index) => {
                    return cellValue ? cellValue : '--'
               }

               onMounted(() => {
                    if (route.query.state) {
                         param.state = route.query.state;
                         state.curState = route.query.state
                    } else {
                         state.curState = 0
                    }
                    getAppList()
               })

               router.beforeEach((to, from, next) => {
                    if (from.path == to.path) {
                         if (to.query.state) {
                              param.state = to.query.state;
                              state.curState = to.query.state
                         } else {
                              delete param.state
                              state.curState = 0
                         }
                         getAppList();
                    }
                    next();
               })

               return {
                    ...toRefs(state),
                    selState,
                    appList,
                    search,
                    toDetail,
                    visiOrder,
                    orderList,
                    viewOrder,
                    formatter,
                    handleCurrentChange
               }
          },


     }
</script>

<style lang="scss">
     .has-gutter {
          background: #F4F4F4 !important;
     }

     .el-table thead tr {
          background-color: #F4F4F4;
     }

     .el-table th {
          background-color: #F4F4F4;
     }

     .designer_application_orderList {
          float: left;
          width: 1009px;
          background-color: #fff;
          margin-left: 15px;

          .title {
               height: 60px;
               border-bottom: 1px solid #EEEEEE;
               line-height: 60px;
               padding-left: 20px;
               font-size: 16px;
               font-family: SourceHanSansCN-Medium, SourceHanSansCN;
               font-weight: 500;
               color: #666666;
          }

          .des_app_list {
               padding: 2px 20px 20px;

               .list_state {
                    border-bottom: 1px solid #F2F2F2;

                    .state {
                         height: 50px;
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         color: #666666;
                         margin-right: 30px;
                         line-height: 50px;
                         width: 60px;
                         text-align: center;
                         cursor: pointer;

                         &.chosen {
                              color: $colorMain;
                              box-shadow: 0px -2px 0px $colorMain inset;
                         }
                    }

                    .state_search {
                         width: 285px;
                         height: 32px;
                         background: #FFFFFF;
                         border: 1px solid #D9D9D9;
                         padding-left: 8px;

                         input {
                              width: 200px;
                              height: 30px;
                              border: none;
                              outline: none;
                         }

                         .icon {
                              border-left: 1px solid #D9D9D9;
                              width: 42px;
                              height: 32px;
                              cursor: pointer;
                         }

                         .el-icon-close {
                              cursor: pointer;
                         }
                    }
               }

               .list_table {
                    margin-top: 20px;
               }

               .options {
                    .txt17 {
                         display: inline-block;
                         padding: 0 5px;
                         border-left: 1px solid #999;
                         color: rgba(102, 102, 102, 1);
                         cursor: pointer;

                         &:first-child {
                              border-left: none;
                         }

                         &:hover {
                              color: $colorMain;
                         }
                    }
               }
          }

          .el-dialog__header {
               display: none;
          }

          .el-dialog__body {
               padding: 0 !important;
          }
     }

     .group1 {

          .main1,
          .main11 {
               height: 46px;
               border-radius: 4px 4px 0 0;
               background-color: rgba(248, 248, 248, 1);
               width: 100%;
               justify-content: center;
               align-items: flex-start;
               padding-left: 20px;

               .mod1 {
                    z-index: auto;
                    height: 16px;
                    justify-content: space-between;
                    width: 100%;
                    padding-right: 15px;

                    .txt1 {
                         display: block;
                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         text-align: left;
                         margin-top: 1px;
                    }

                    .label1 {
                         z-index: 7;
                         width: 16px;
                         height: 16px;
                         cursor: pointer;
                    }
               }
          }
     }

     .main24 {
          padding-left: 30px;
          padding-top: 20px;
     }

     .main22 {
          z-index: 11;
          height: 309px;
          border-radius: 3px 3px 0 0;
          align-self: center;
          margin-top: 20px;
          width: 747px;
          justify-content: flex-start;

          .mod2 {
               z-index: auto;
               width: 747px;
               height: 297px;

               .group2 {
                    height: 40px;
                    border-radius: 3px 3px 0 0;
                    background: #eee;
                    padding-left: 10px;

                    .word0 {
                         width: 56px;
                         height: 40px;
                    }

                    .word1 {

                         width: 126px;
                         height: 40px;
                         text-align: center;
                         font-size: 14px;
                         line-height: 40px;

                    }

                    .word2 {

                         width: 156px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;
                    }

                    .word3 {

                         width: 120px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;

                    }

                    .word4 {
                         width: 120px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;
                    }

                    .word5 {
                         width: 140px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;
                    }


               }

               .group3 {
                    height: 53px;
                    padding-left: 10px;

                    .word6 {
                         width: 56px;
                         height: 53px;
                         display: block;

                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 53px;
                    }

                    .txt2 {

                         width: 126px;
                         height: 53px;
                         display: block;

                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 53px;
                    }

                    .info1 {

                         width: 156px;
                         height: 53px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 53px;
                    }

                    .word7 {

                         width: 120px;
                         height: 53px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         line-height: 53px;
                         text-align: center;

                    }

                    .txt3 {

                         width: 120px;
                         height: 53px;
                         display: block;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 53px;
                    }

                    .bd2 {
                         height: 53px;
                         border-radius: 2px;
                         border: none;
                         background-color: rgba(255, 255, 255, 1);
                         width: 140px;
                         justify-content: center;
                         align-items: center;

                         .word8 {
                              width: 95px;
                              height: 30px;
                              display: block;
                              color: rgba(40, 95, 222, 1);
                              border: 1px solid rgba(40, 95, 222, 1);
                              font-size: 14px;
                              text-align: center;
                              line-height: 30px;
                              cursor: pointer;
                         }
                    }

               }
          }
     }
</style>